import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop } from '~styles/global'
import { graphql } from 'gatsby'
import Section from '~components/Section'
import DataViewer from '~utils/DataViewer'
import Image from '~components/Image'
import RichText from '~components/RichText'
import Button from '~components/Button'
import ProjectTile from '~components/ProjectTile'
import Slider from '~components/Slider'
import { useState } from 'react'
import { DateTime } from 'luxon'

const Article = ({ data }) => {
	const page = data?.sanityArticle
	const [dragging, setDragging] = useState()

	const date = DateTime.fromISO(page.publishedAt)

	return (
		<>
			<Section>
				<Header className='h1-serif'>News</Header>
			</Section>
			<Body>
				<Left>
					<StickyImage image={page?.featureImage}/>
				</Left>
				<Right>
					<Date className='h2-serif'>{date.toLocaleString(DateTime.DATE_SHORT)}</Date>
					<Title className='h1-serif'>{page.title}</Title>
					<Tags>
						{page?.tags?.map(tag => (
							<Tag key={tag.title} color={tag.colour}>{tag.title}</Tag>
						))}
					</Tags>					
					<Text>
						<RichText content={page?.articleContent}/>
					</Text>
					<ExternalLinks>
						{page.externalLinks?.map(link => ( 
							<Button
								key={link.text} 
								link={link.link}
							>
								{link.text}
							</Button>
						))}
					</ExternalLinks>
				</Right>
			</Body>
			<Featured>
				<FeaturedTitle className='h1-serif'>
					{page.featuredProjectsTitle}
				</FeaturedTitle>
				{page.featuredProjects?.length > 0 && 
					<FeaturedSlider 
						totalSlides={page.featuredProjects.length}
						onDragging={value => setDragging(value)}
					>
						{page.featuredProjects.map((project) => (
							<Project 
								key={project.title} 
								content={project} 
								featured
								cursorMessage={project.title}
								css={css`pointer-events: ${dragging ? 'none' : 'all'};`}
							/>
						))}
					</FeaturedSlider>
				}
			</Featured>
			<DataViewer data={page}/>
		</>
	)
}

const Header = styled.div`
	grid-column: span 16;
	margin-top: 140px;
	padding-bottom: var(--s);
	border-bottom: 1px solid;
	margin-bottom: var(--s);
	${mobile}{
		margin-bottom: var(--m-s);
		padding-bottom: var(--m-s);
	}
`
const Body = styled(Section)`
	margin-bottom: var(--m);
	${mobile}{
		margin-bottom: var(--m-m);
	}
`
const Col = styled.div`
	grid-column: span 8;
	${mobile}{
		grid-column: span 16;
	}
`
const Left = styled(Col)`
	position: relative;
	${mobile}{
		margin-bottom: var(--m-s);
	}
`
const StickyImage = styled(Image)`
	position: sticky;
	top: 78px;
	${desktop}{
		top: 74px;
	}
	${tablet}{
		top: 68px;
	}
`
const Right = styled(Col)`
`
const Date = styled.div`	
	margin-bottom: 44px;
	${tablet}{
		margin-bottom: 23px;
	}
`
const Title = styled.div`
	margin-bottom: 44px;
	${tablet}{
		margin-bottom: 31px;
	}
`
const Tags = styled.div`
	margin-bottom: 54px;
`
const Tag = styled.div`
	background-color: ${props => props.color};
	display: inline-block;
	padding: 8px 11px 4px;
	margin-right: 14px;
`
const Text = styled.div`
	margin-bottom: var(--s);
	${mobile}{
		margin-bottom: var(--m-s);
	}
`
const ExternalLinks = styled.div`
`
const Featured = styled(Section)`
	margin-bottom: var(--m);
	${mobile}{
    margin-bottom: var(--m-l);
		overflow: hidden;
	}
`
const FeaturedSlider = styled(Slider)`
	grid-column: span 16;
	${mobile}{
		.flickity-viewport {
			overflow: visible;
		}
	}
`
const FeaturedTitle = styled.div`
	border-top: 1px solid;
	grid-column: span 16;
	padding-top: var(--s);
	margin-bottom: var(--s);
	${mobile}{
		margin-bottom: 42px;
		padding-top: var(--m-s);
		margin-bottom: var(--m-s);
	}
`
const Project = styled(ProjectTile)`
	width: calc(50% - (25px / 2));
	margin-right: 25px;
	${mobile}{
		width: 80%;
	}
`

Article.propTypes = {
	data: PropTypes.object
}

export const query = graphql`
	  query ArticleQuery($slug: String) {
    	sanityArticle(slug: {current: {eq: $slug}}) {
				title
				slug {
					current
				}
				publishedAt
				tags{
					title
					colour
				}
				featureImage{
					...imageWithAlt
				}
				articleContent: _rawArticleContent(resolveReferences: {maxDepth: 4})
				externalLinks{
					...button
				}
				featuredProjectsTitle
				featuredProjects {
					thumbnailType
					thumbnail {
						wideLandscape {
							...imageWithAlt
						}
					}
					thumbnailVideo {
						wideLandscape {
							...video
						}
					}
					title
					slug {
						current
					}
					service {
						title
					}
      		shortText: _rawShortText(resolveReferences: {maxDepth: 5})
				}
				seo{
					...seo
				}
			}
		}
`

export default Article